import {
	ClipboardListIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "Marketing Strategy",
	desc: "Generate a marketing strategy for your business that will attract new customers. ",
	category: "Social",
	Icon: ClipboardListIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "purple-500",
	toColor: "indigo-500",

	to: "/ai/social/marketingplan",
	api: "/ai/social/marketingplan",

	output: {
		title: "Marketing Strategy",
		desc: "The following marketing strategy was generated",
		Icon: false,
		color: "blue",
	},

	prompts: [{
		title:"Enter the following information to have your marketing strategy generated.",
		desc: " ",
		// n: 1,
		prompts: [
            { 
				title: "Business/brand overview", 
				attr: "b_desc",  
				value: "", 
				placeholder: "", 
				label: "",
				type: "textarea",
				maxLength: 1000,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: "Hylo AI is an AI SASS company.  Hylo has tools for entrepreneurs that helps them during the ideation and planning phases of business building. ",
			},
            { 
				title: "Marketing Channels", 
				attr: "b_plat",  
				value: "", 
				placeholder: "", 
				label: "",
				type: "textarea",
				maxLength: 500,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: "Twitter, Instagram, Tik Tok",
			},
            { 
				title: "Goals or Objectives", 
				attr: "b_goal",  
				value: "", 
				placeholder: "", 
				label: "",
				type: "textarea",
				maxLength: 500,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: "50k MRR in 12 months",
			},
			{ 
				title: "Target Audience (optional))", 
				attr: "target",  
				value: "", 
				placeholder: "", 
				label: "",
				type: "textarea",
				maxLength: 500,
				// max: 100,
				min: 0,
				required: false,
				error: "",
				example: "",
			},
			{ 
				title: "Unique Value Proposition (optional)", 
				attr: "uvp",  
				value: "", 
				placeholder: "", 
				label: "",
				type: "textarea",
				maxLength: 500,
				// max: 100,
				min: 0,
				required: false,
				error: "",
				example: "",
			},
            

		],
		example: {
			output: `Marketing Strategy for Hylo AI:

Target Audience:
Hylo AI's target audience is entrepreneurs who are in the ideation and planning phase of business building. Our primary target audience is tech-savvy individuals aged 25-45 who are looking for innovative solutions to help them in their business planning.
			
Social Media Platforms:
To reach our target audience, we will focus our efforts on LinkedIn, Twitter, and Facebook. LinkedIn will be our primary platform for B2B marketing, while Twitter and Facebook will be used for B2C marketing. We will also utilize paid social media advertising to reach a wider audience.
			
Goals and Objectives:
Our primary goal is to achieve $50,000 in Monthly Recurring Revenue (MRR) within the next 12 months. To achieve this goal, we will focus on the following objectives:
			
1. Increase Brand Awareness: We will increase brand awareness through targeted social media advertising, email marketing campaigns, and content marketing.
			
2. Generate Leads: We will generate leads through targeted social media advertising, email marketing campaigns, and content marketing.
			
3. Convert Leads into Customers: We will convert leads into customers through targeted email marketing campaigns, personalized sales outreach, and product demos.
			
4. Retain Customers: We will retain customers through exceptional customer service, ongoing product updates, and personalized communication.
			
Action Plan:
			
1. Increase Brand Awareness:
- Develop a content marketing strategy that includes blog posts, social media posts, and email newsletters.
- Create a social media advertising campaign targeting our primary audience on LinkedIn, Twitter, and Facebook.
- Develop an email marketing campaign targeting our primary audience.
			
2. Generate Leads:
- Create gated content, such as whitepapers and e-books, to generate leads.
- Develop a lead magnet to capture contact information from potential customers.
- Utilize targeted social media advertising to generate leads.
			
3. Convert Leads into Customers:
- Develop a personalized sales outreach strategy to convert leads into customers.
- Offer product demos to potential customers to showcase our product's capabilities.
- Develop a lead nurturing email campaign to keep potential customers engaged.
			
4. Retain Customers:
- Provide exceptional customer service through personalized communication and timely support.
- Offer ongoing product updates and improvements to keep customers engaged.
- Develop a customer referral program to incentivize customers to refer new customers.
			
Potential Pitfalls or Challenges:
One potential challenge is the highly competitive nature of the AI SASS market. To overcome this challenge, we will focus on developing a unique value proposition that differentiates us from our competitors. Another potential challenge is the difficulty of reaching our target audience through social media advertising. To overcome this challenge, we will conduct thorough market research to ensure our advertising efforts are highly targeted and effective.
			
Now that you have your action plan, develop your marketing strategy with our Marketing Plan Tool!`,
			// outputs: [
			// 	"The sun is very old, over 4.5 billion years",
			// 	"At 10,000 degrees, sun is also very hot",
			// 	"Gasses called coronal mass ejections erupt from the sun",
			// ],
			// Icon: RefreshIcon,
			color: "blue",
		}
	}]
		
}

export default obj