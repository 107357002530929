import {
	ViewGridIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "Content Calender",
	desc: "Create a week-long social media content calendar to boost your online presence.",
	category: "Social",
	Icon: ViewGridIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "purple-500",
	toColor: "indigo-500",

	to: "/ai/social/socialcalendar",
	api: "/ai/social/socialcalendar",

	output: {
		title: "Content Calendar",
		desc: "The following content calendar was generated",
		Icon: false,
		color: "blue",
	},

	prompts: [{
		title:"Provide a brief description of your business, preferred social media platform, and the primary goal of your social media account.",
		desc: " ",
		// n: 1,
		prompts: [
            { 
				title: "Business Description", 
				attr: "b_desc",  
				value: "", 
				placeholder: "Human-grade, organic dog food for small breed dogs with specific dietary needs such as digestive issues or allergies.", 
				label: "",
				type: "textarea",
				maxLength: 150,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: "Human-grade, organic dog food for small breed dogs with specific dietary needs such as digestive issues or allergies.",
			},
            { 
				title: "Platform", 
				attr: "b_plat",  
				value: "", 
				placeholder: "Instagram", 
				label: "",
				type: "text",
				maxLength: 30,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: "Instagram",
			},
            { 
				title: "Goal", 
				attr: "b_goal",  
				value: "", 
				placeholder: "Grow 100k followers", 
				label: "",
				type: "textarea",
				maxLength: 150,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: "Grow 100k followers",
			},
            

		],
		example: {
			output: `Day 1:
- Post 1 (9 AM): Timeline post – High-quality image featuring a happy small dog with the human-grade, organic dog food. Caption: "Introducing our new range of human-grade, organic dog food for your small furry friends 🐶🍽️ #dogfood #organic #smallbreeds"
- Post 2 (2 PM): Reel – A short video showcasing the ingredients and preparation process of the dog food. Caption: "A behind-the-scenes look at what goes into our human-grade, organic dog food! 🥦🥕🍗 #dogfoodprep #organicdogfood"
- Post 3 (7 PM): Story – Share a customer testimonial about how the product helped their small breed dog with dietary issues.
			
Day 2:
- Post 1 (9 AM): Timeline post – Infographic highlighting the benefits of organic dog food for dogs with digestive issues or allergies. Caption: "Why choose human-grade, organic dog food for your small breed? Here's why! 🌿🐾 #healthydogfood #digestivehealth"
- Post 2 (5 PM): Reel – A fun video of dogs trying the product for the first time and loving it! Caption: "Watch these adorable pups enjoy our delicious organic dog food! 😋🐕 #dogtastetest #yum"`,
			// outputs: [
			// 	"The sun is very old, over 4.5 billion years",
			// 	"At 10,000 degrees, sun is also very hot",
			// 	"Gasses called coronal mass ejections erupt from the sun",
			// ],
			// Icon: RefreshIcon,
			color: "blue",
		}
	}]
		
}

export default obj