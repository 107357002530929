import {
	LightBulbIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "Business Idea",
	desc: "Start your journey to success today with our AI-powered idea generator.",
	category: "Business",
	Icon: LightBulbIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "indigo-500",
	toColor: "purple-500",

	to: "/ai/business/businessidea",
	api: "/ai/business/businessidea",

	output: {
		title: "Business Idea",
		desc: "The following business ideas have been generated",
		// Icon: RefreshIcon,
		// color: "",
	},

	prompts: [{
		title:"Entry Text",
		desc: " ",
		// n: 1,
		prompts: [
			{ 
				title: "Business Category", 
				attr: "bus_cat",  
				value: "", 
				placeholder: "Healthcare and technology", 
				label: " ",
				type: "text",
				maxLength: 150,
				// max: 100,
				min: 5,
				required: true,
				error: "",
				example: "Real Estate and AI",
			},
			
		],
		example: {
			output: `
AI-powered real estate investment platform: Develop a platform that leverages AI algorithms to analyze real estate data and provide investment recommendations to potential investors. The platform could use machine learning to predict the future value of properties and provide insights on the best markets to invest in.`,

//2. Smart home management system: Create a smart home management system that uses AI to optimize energy usage and reduce utility bills for homeowners. The system could monitor energy consumption and adjust heating and lighting settings automatically based on user preferences and occupancy patterns.
			
//3. Chatbot-powered real estate agent: Develop a chatbot-powered real estate agent that uses AI to provide 24/7 support to potential buyers and sellers. The chatbot could answer common questions about the real estate market, provide recommendations on properties, and facilitate transactions. The chatbot could also learn from previous interactions and improve its responses over time.`,
			// outputs: [],
			// Icon: RefreshIcon,
			// color: "",
		}
	}]
		
}

export default obj

