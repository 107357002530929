import {
	TemplateIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "Business Plan",
	desc: "Quickly breakdown and analyze business idea's and ventures.",
	category: "Not Visible",
	Icon: TemplateIcon,
	// tags: [],
	permissions: ['user'],
	
    fromColor: "indigo-500",
	toColor: "purple-500",

    to: "/ai/business/businessplan",
	api: "/ai/business/businessplan",

	output: {
		title: "Business Plan",
		desc: "The following business plan has been generated",
		// Icon: RefreshIcon,
		// color: "",
	},

	prompts: [{
		title:"Enter the business name and detailed description of the business idea you want to evaluate.",
		desc: " ",
		// n: 1,
		prompts: [
			{ 
				title: "Business Name", 
				attr: "b_name",  
				value: "", 
				placeholder: "Gin and Juicery", 
				label: " ",
				type: "text",
				maxLength: 150,
				// max: 100,
				min: 5,
				required: true,
				error: "",
				example: "Yenisi Beauty",
			},
			{ 
				title: "Business Description", 
				attr: "b_desc",  
				value: "", 
				placeholder: "100% organic fresh pressed juicery made to order. Offers juice flavors that mock cocktails", 
				label: " ",
				type: "textarea",
				maxLength: 1000,
				// max: 100,
				min: 5,
				required: true,
				error: "",
				example: "a 100% certified organic skin brand using real ingredients. to moisturize exfoliate and grow either your skin or hair.",
			},
			
		],
		example: {
			output: `Customer Segments: Beauty-conscious individuals who value health, wellness and sustainability and are looking for high-quality skin and hair care products. 
Early Adopters: Health-conscious individuals who prioritize natural and organic ingredients in their beauty products. Customers who have tried other skin and hair care products with harmful chemicals and are looking for a more natural alternative. 
Problem: Many skin and hair care products contain harmful chemicals that can harm skin and hair over time. Consumers are looking for products that are 100% organic and made with real ingredients. 
Unique Value Proposition: Yenisi Beauty offers 100% certified organic skin and hair care products that are made with real ingredients. These products are designed to moisturize, exfoliate and grow skin and hair in a healthy and sustainable way. 
Solutions: 
	Moisturizing: Our products are formulated with organic 
	ingredients like coconut oil, shea butter and avocado oil to
	hydrate skin and hair without leaving it feeling greasy. 
	Exfoliating: Our products contain natural ingredients like sugar, 
	sea salt and coffee to gently exfoliate and remove dead skin 
	cells.
	Growing: Our products are formulated with ingredients like 
	biotin, keratin and aloe vera to promote healthy growth and 
	strengthen skin and hair.
Channels:
	Online: Our products are available on our website and on 
	popular e-commerce platforms like Amazon.
	Retail: We partner with health and wellness stores to offer our 
	products in-store.
Revenue Streams: 
	Retail sales: Revenue generated from the sale of our products 
	in-store and online.
	Wholesale partnerships: Revenue generated from partnerships 
	with health and wellness stores.
Key Metrics: 
	Conversion rate: Number of customers who make a purchase 
	after visiting our website.
	Repeat purchase rate: Number of customers who make repeat 
	purchases.
	Net promoter score (NPS): Customer satisfaction score.
	Customer acquisition cost (CAC): The cost of acquiring new 
	customers through advertising and marketing.
Unfair Advantage:
	Strong brand: Our commitment to using only 100% certified 
	organic ingredients and our focus on healthy, sustainable skin
	and hair care sets us apart from other beauty brands.
	Positive customer reviews: Our products have received positive 
	feedback from customers, which helps us attract new 
	customers and build brand loyalty.`,
			// outputs: [],
			// Icon: RefreshIcon,
			color: "blue",
		}	
	}]
		
}

export default obj

