import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';
import { IconDashboard,  } from './Icons';
import { SwitchHorizontalIcon, ScaleIcon, DatabaseIcon, UserCircleIcon,
} from '@heroicons/react/outline'

import './style.css';
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { inject } from 'mobx-react'
import logo from './images/logo.png';
import pic from './images/pic.png';
import vid from './video/Sizzle1.mp4';

@inject('store')
class Landing extends Component {
  render() {
    return (
      <>
<Helmet>
    <title>Landing Page</title>
</Helmet>





<html>
<head>
    <script src='https://cdn.tailwindcss.com/'></script>
</head>
<body>
    <div class="bg-white absolute top-0 w-full z-10">
        <div className="border-b border-gray-300 bg-white shadow-sm ">
										<div className="container flex mx-auto px-4 md:pr-10 max-w-7xl flex select-none">
														<NavLink to="/"
														 exact
														 tabIndex={-1}
														 onClick={()=>this.props.store.toolsKeyword = ""}
														 activeClassName="bg-white-100 hover: text-gray-800 transition"
														 className="text-lg flex py-5 px-6 lg:py-4 lg:px-0 cursor-pointer  pl-0 rounded-t-md font-semibold transition items-center pl-0 ">
															
                              <img className="w-40" src={logo} alt="Think" />

															</NavLink>
														 
														<div className="relative text-gray-400 focus-within:text-green-500 flex flex-1 ">
															<label htmlFor="q" className="absolute inset-y-0 left-0 top-0 bottom-0 hidden md:flex items-center lg:pl-2 ">
																	<div type="submit" className="p-2 focus:outline-none focus:shadow-outline ">
																	{/* <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-6 h-6 transition"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg> */}
																	</div>
															</label>
														
														</div>

                                                        <NavLink to="/login"
														 exact
														 tabIndex="-1"
														 activeClassName="bg-green-100 hover:bg-green-200 text-green-800 transition"
														 className={`text-lg flex py-3 px-6 xl:py-4 xl:px-8 cursor-pointer ${this.fromColor} hover:bg-gray-100 rounded-t-md font-medium transition items-center`}><UserCircleIcon className="w-7 h-7 lg:mr-4 transition" />
															<div className="hidden lg:block"> Log In</div>
														</NavLink>
														{/* <NavLink to="/"
														 exact
														 tabIndex={-1}
														 onClick={()=>this.props.store.toolsKeyword = ""}
														 activeClassName="bg-white-100 hover:bg-gray-200 text-gray-800 transition"
														 className="text-lg flex py-3 px-6 lg:py-4 lg:px-8 cursor-pointer hover:bg-gray-100 rounded-t-md font-medium transition items-center">
															 <div className="hidden lg:block">Sign Up</div>
															</NavLink> */}

     
     
     
     
     
                                                           
                                                            <div className="hidden lg:block" style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px'}} >
  <Link to="/signup" className="hidden lg:block btn bg-gradient-to-r from-purple-900 to-purple-700 text-center text-white hover:bg-purple-700 hover:text-white py-4 px-10 rounded-lg mr-5">
    <b>Sign Up</b>
  </Link>
</div>
      

														


												</div>
												
								</div>
        <div class="relative w-full bg-white">
            <div class="relative items-center justify-center w-full overflow-x-hidden lg:pb-32 sm:pt-8 md:pt-12">
                <div class="container flex flex-col items-center justify-between h-full max-w-7xl px-5 mx-auto lg:flex-row">
                    <div class="z-30 flex flex-col items-center w-full max-w-xl text-center lg:items-start lg:w-1/2 lg:pt-24 lg:text-left">
                        <h1 class="relative mb-4 pt-8 text-4xl font-black leading-tight text-black lg:text-5xl lg:pr-16 lg:mb-8 primary_text editable" data-content="website-headlines">Unlock Your Entrepreneurial & Creative Potential with Hylo AI</h1>
                        <p class="mb-8 text-lg text-gray-600 lg:pr-20 secondary_text editable" data-content="website-subheadlines">Use our AI-powered tools to launch and scale your business in a fraction of the time!</p>
                        <Link to="/signup" className="btn bg-gradient-to-r from-purple-900 to-purple-700 text-white hover:bg-purple-700 hover:text-white py-3 px-8 rounded-lg">
                        <b>Start Free Trial</b>
                        </Link>
                        <div class="flex-col hidden mt-12 sm:flex lg:mt-24">

                        </div>
                        <div class="flex flex-col pt-5 justify-start mb-3 space-x-0 space-y-2 text-xs text-gray-600 md:flex-row md:justify-center md:space-x-8 md:space-y-0">
                            <div class="flex items-center">
                                <svg viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 mr-1 text-green-600">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                </svg>
                                <p class="text-sm text-gray-600 secondary_text editable">No credit card required</p>
                            </div>
                            <div class="flex items-center">
                                <svg viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 mr-1 text-green-600">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                </svg>
                                <p class="text-sm text-gray-600 secondary_text editable">Free plan available</p>
                            </div>
                            <div class="flex items-center mb:pb-6">
                                <svg viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 mr-1 text-green-600">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                </svg>
                                <p class="text-sm text-gray-600 secondary_text editable">Cancel anytime</p>
                            </div>
                        </div>

                    </div>
                    <div class="relative flex flex-col items-end justify-center w-full  lg:w-1/2 lg:pl-10 pt-2">
                        <div class="container relative left-0 w-full max-w-4xl lg:absolute lg:w-screen">

                        <video src={vid} controls={false} autoPlay loop muted className="rounded-xl" />                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-white">
                <div class="px-4 pt-12 mx-auto sm:max-w-7xl md:max-w-7xl lg:max-w-7xl md:px-24 lg:px-8 lg:py-20">
                    <div class="grid gap-6 row-gap-10 lg:grid-cols-2" data-content="how-it-works">
                        <div class="lg:py-6 lg:pr-16">
                        <h2 class="mb-8 font-bold text-black text-4xl primary_text text-center md:text-left lg:editable">How it works</h2>
                            <div class="flex" data-subcontent="step_1">
                                <div class="flex flex-col items-center mr-4">
                                    <div>
                                        <div class="flex items-center justify-center w-10 h-10 border rounded-full">
                                            <svg class="w-4 text-indigo-600" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                                                <line fill="none" stroke-miterlimit="10" x1="12" y1="2" x2="12" y2="22"></line>
                                                <polyline fill="none" stroke-miterlimit="10" points="19,15 12,22 5,15"></polyline>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="w-px h-full bg-gray-300"></div>
                                </div>
                                <div class="pt-1 pb-8 w-full">
                                    <p class="mb-2 text-xl text-gray-900 font-bold primary_text editable" data-subcontent="step-title">Select a tool</p>
                                    <p class="text-base text-gray-600 secondary_text editable" data-subcontent="step-content">Choose from our wide variety of AI tools, each designed to meet the unique needs of entrepreneurs and creators.</p>
                                </div>
                            </div>
                            <div class="flex" data-subcontent="step_2">
                                <div class="flex flex-col items-center mr-4">
                                    <div>
                                        <div class="flex items-center justify-center w-10 h-10 border rounded-full">
                                            <svg class="w-4 text-indigo-600" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                                                <line fill="none" stroke-miterlimit="10" x1="12" y1="2" x2="12" y2="22"></line>
                                                <polyline fill="none" stroke-miterlimit="10" points="19,15 12,22 5,15"></polyline>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="w-px h-full bg-gray-300"></div>
                                </div>
                                <div class="pt-1 pb-8 w-full">
                                    <p class="mb-2 text-xl text-gray-900 font-bold primary_text editable" data-subcontent="step-title">Fill in the details</p>
                                    <p class="text-base text-gray-600 secondary_text editable" data-subcontent="step-content">Input your specifications and our AI will get to work. </p>
                                </div>
                            </div>
                            <div class="flex" data-subcontent="step_3">
                                <div class="flex flex-col items-center mr-4">
                                    <div>
                                        <div class="flex items-center justify-center w-10 h-10 border rounded-full">
                                            <svg class="w-6 text-indigo-600" stroke="currentColor" viewBox="0 0 24 24">
                                                <polyline fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="6,12 10,16 18,8"></polyline>
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                                <div class="pt-1 pb-0 w-full">
                                    <p class="mb-2 text-xl text-gray-900 font-bold primary_text editable" data-subcontent="step-title">Generate Al content</p>
                                    <p class="text-base text-gray-600 secondary_text editable" data-subcontent="step-content">Our specialized AI analyzes your input and generates high-quality, tailored content in just seconds.</p>
                                </div>
                            </div>
                        </div>
                        <div class="relative">
                        <img className="" src={pic} alt="Think" />
                        </div>
                    </div>
                </div>
            </div><div class="bg-white">

                <div class="px-6 py-16 mx-auto md:px-24 lg:px-8 lg:py-20">
                    <div class="pb-2 mb-6 md:mb-10">
    <h2 class="mb-6 font-sans text-3xl font-bold tracking-tight text-center md:text-gray-900 sm:text-4xl sm:leading-none primary_text editable" data-content="website-headlines">
        Streamline Your Workflow's with AI Tools <br/>Designed for Entrepreneurs & Creators</h2>
</div>

                    <div class="grid gap-8 row-gap-10 sm:grid-cols-2 lg:grid-cols-3 text-center">
  
  <div class="max-w-md" data-content="features">
  <div class="flex items-center justify-center">
  <div class="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-purple-50 bg-editable ">
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 20 20" class="text-purple-600">
  <g fill="currentColor">
    <path d="M9 2a1 1 0 0 0 0 2h2a1 1 0 1 0 0-2H9Z"/>
    <path fill-rule="evenodd" d="M4 5a2 2 0 0 1 2-2a3 3 0 0 0 3 3h2a3 3 0 0 0 3-3a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V5Zm9.707 5.707a1 1 0 0 0-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
  </g>
</svg>
  </div>
</div>
    <h6 class="mb-2 font-semibold text-gray-900 text-base  text-lg text-center md:text-center leading-5 primary_text editable" data-subcontent="feature-title">Business Planning</h6>
    <p class="mb-3 text-md text-gray-900 secondary_text text-center md: editable" data-subcontent="feature-content"> Take your business from concept to launch with ease using Hylo AI's suite of creative business tools.</p>
    {/* <a href="/" class="inline-flex items-center font-semibold transition-colors duration-200 text-indigo-600 hover:text-indigo-700 secondary_text link-editable">Learn more</a> */}
  </div>
  <div class="max-w-md" data-content="features">
  <div class="flex items-center justify-center">
  <div class="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-purple-50 bg-editable ">
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 20 20">
  <path fill="#8449D6" fill-rule="evenodd" d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 0 0 4.083 9ZM10 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16Zm0 2c-.076 0-.232.032-.465.262c-.238.234-.497.623-.737 1.182c-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556c-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4Zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0 1 15.917 9h-1.946Zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556c.24.56.5.948.737 1.182c.233.23.389.262.465.262c.076 0 .232-.032.465-.262c.238-.234.498-.623.737-1.182c.389-.907.673-2.142.766-3.556Zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 0 1-2.783 4.118Zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 0 0 2.783 4.118Z" clip-rule="evenodd"/>
</svg>

  </div>
</div>
    <h6 class="mb-2 font-semibold text-gray-900 text-base text-lg text-center md: leading-5 primary_text editable" data-subcontent="feature-title">Social Media</h6>
    <p class="mb-3 text-md text-gray-900 secondary_text text-center md: editable" data-subcontent="feature-content"> Streamline your social media strategy with the use of our AI-generated content and strategies.</p>
    {/* <a href="/" class="inline-flex items-center font-semibold transition-colors duration-200 text-indigo-600 hover:text-indigo-700 secondary_text link-editable">Learn more</a> */}
  </div>
  <div class="max-w-md" data-content="features">
  <div class="flex items-center justify-center">
  <div class="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-purple-50 bg-editable ">
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 20 20">
  <path fill="#8449D6" fill-rule="evenodd" d="M11.3 1.046A1 1 0 0 1 12 2v5h4a1 1 0 0 1 .82 1.573l-7 10A1 1 0 0 1 8 18v-5H4a1 1 0 0 1-.82-1.573l7-10a1 1 0 0 1 1.12-.38Z" clip-rule="evenodd"/>
</svg>

  </div>
</div>
    <h6 class="mb-2 font-semibold text-gray-900 text-base text-lg text-center md: leading-5 primary_text editable" data-subcontent="feature-title">Boost Your Productivity</h6>
    <p class="mb-3 text-md text-gray-900 secondary_text text-center md: editable" data-subcontent="feature-content"> Maximize your time and effort with Hylo AI's text simplification and expansion tools, allowing you to create high-quality copy with ease.</p>
    {/* <a href="/" class="inline-flex items-center font-semibold transition-colors duration-200 text-indigo-600 hover:text-indigo-700 secondary_text link-editable">Learn more</a> */}
  </div>
  <div class="max-w-md" data-content="features">
  <div class="flex items-center justify-center">
  
</div>
  </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="py-20 bg-purple-50">
            <div class="px-4 mx-auto text-center max-w-7xl sm:px-6 lg:px-8">
                <h2 class="text-5xl font-extrabold tracking-tight text-gray-900 sm:text-4xl primary_text editable" data-content="cta">Boost Your Productivity and Grow Your Business</h2>
                <p class="max-w-md mx-auto mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl secondary_text editable" data-content="website-subheadlines">Analyze Your Ideas and Strategies with the Same Level of Accuracy and Efficiency as a Large Corporation.</p>
                <div class="flex justify-center mt-8 space-x-3">

                    <Link to="/signup" className="btn bg-gradient-to-r from-purple-900 to-purple-700 text-white hover:bg-purple-700 hover:text-white py-3 px-8 rounded-lg mr-5">
                    <b>Sign Up Today</b>
                    </Link>
                    {/* <a href="#" class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-indigo-600 bg-indigo-100 rounded-md hover:bg-indigo-200 hover:text-indigo-600 btn-editable">Learn more</a> */}
                </div>
            </div>
        </div>
        {/* <div class="w-full bg-purple-50 sm:py-16 py-12 md:py-20 relative">
            <div class="max-w-7xl mx-auto px-10">
                <div class="flex flex-col mb-7 sm:mb-16 items-start md:items-center justify-center">
                    <p class="sm:tracking-widest sm:text-base text-sm uppercase font-medium text-purple-900 primary_text editable">What people are saying about us</p>
                    <h2 class="text-gray-900 mt-2 text-2xl sm:text-4xl tracking-tight font-bold sm:font-extrabold md:text-5xl dark:text-white primary_text editable">Don't just take our word for it.</h2>
                </div>
                <div class="grid grid-cols-4 sm:grid-cols-8 lg:grid-cols-12 gap-6 w-full">
                    <div class="col-span-4 space-y-6">
                        <div class="h-auto bg-white rounded-xl shadow-sm p-6 relative bg-editable">
                            <div class="flex items-center border-b relative border-gray-100 border-solid pb-5 mb-5">
                                <img src="https://cdn.copymatic.ai/editor/img/01-john-robertson.jpeg" class="object-cover is_avatar rounded-full mr-3 w-12 h-12 img-editable" />
                                <div class="relative">
                                    <p class="font-semibold text-gray-600 leading-none my-1">John Robertson</p>
                                    <p class="font-medium text-gray-400 text-sm">Social Media Influencer</p>
                                </div>
                            </div>
                            <p class="text-gray-500 z-10 leading-6 text-base relative bg-transparent shadow-none p-0" data-content="testimonials">"As a content creator, I have to produce high-quality content frequently. With Hylo, I can now generate content that's on-brand, informative, and engaging for my audience. It has helped me save time and increased my productivity significantly."</p>
                            <svg class="h-auto absolute z-0 top-0 right-0 w-12 opacity-30 mt-6 mr-7 text-purple-300" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true"><path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path></svg>
                        </div>



                        <div class="h-auto bg-white rounded-xl shadow-sm p-6 relative bg-editable">
                            <div class="flex items-center border-b relative border-gray-100 border-solid pb-5 mb-5">
                                <img src="https://cdn.copymatic.ai/editor/img/07-nick-thompson.jpeg" class="object-cover is_avatar rounded-full mr-3 w-12 h-12 img-editable" />
                                <div class="relative">
                                    <p class="font-semibold text-gray-600 leading-none my-1">Nick Thompson</p>
                                    <p class="font-medium text-sm text-gray-400">Entrepreneur</p>
                                </div>
                            </div>
                            <p class="text-gray-500 z-10 leading-6 text-base relative bg-transparent shadow-none p-0" data-content="testimonials">"As a solopreneur, I don't have a team of analysts and researchers to help me make important business decisions. Thanks to Hylo I can have the ablity to leverage the capablites of AI and gain valuable insights to analyze my business."</p>
                            <svg class="h-auto absolute z-0 top-0 right-0 w-12 opacity-30 mt-6 mr-7 text-purple-300" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true"><path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path></svg>
                        </div>


                        <div class="h-auto bg-white rounded-xl shadow-sm p-6 relative bg-editable">
                            <div class="flex items-center border-b relative border-gray-100 border-solid pb-5 mb-5">
                                <img src="https://cdn.copymatic.ai/editor/img/02-mike-samson.jpeg" class="object-cover is_avatar rounded-full mr-3 w-12 h-12 img-editable" />
                                <div class="relative">
                                    <p class="font-semibold text-gray-600 leading-none my-1">Mike Samson</p>
                                    <p class="font-medium text-gray-400 text-sm">Project Manager at Blocknet</p>
                                </div>
                            </div>
                            <p class="text-gray-500 z-10 leading-6 text-base relative bg-transparent shadow-none p-0" data-content="testimonials">"I've been using this AI tool for my creative projects, and I must say it has revolutionized my workflow. It saves me a lot of time and effort, allowing me to focus on other aspects of my business. I highly recommend it to all fellow entrepreneurs and creators."</p>
                            <svg class="h-auto absolute z-0 top-0 right-0 w-12 opacity-30 mt-6 mr-7 text-purple-300" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true"><path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path></svg>
                        </div>
                        
                    </div>

                    <div class="col-span-4 sm:block hidden space-y-5">
                        <div class="h-auto bg-white rounded-xl shadow-sm p-6 relative bg-editable">
                            <div class="flex items-center border-b relative border-gray-100 border-solid pb-5 mb-5">
                                <img src="https://cdn.copymatic.ai/editor/img/04-steve-mitchell.jpeg" class="object-cover is_avatar rounded-full mr-3 w-12 h-12 img-editable" />
                                <div class="relative">
                                    <p class="font-semibold text-gray-600 leading-none my-1">Steve Mitchell</p>
                                    <p class="font-medium text-sm text-gray-400">Blogger</p>
                                </div>
                            </div>
                            <p class="text-gray-500 z-10 leading-6 text-base relative bg-transparent shadow-none p-0" data-content="testimonials">"Hylo AI has changed my life. I used to spend so much of my time, at the very least 30-40 minutes per day, trying to write blog posts that were on-brand and engaging for my audience. Now, with Hylo AI, I am able to spend more time with family and focus on other parts of my business."</p>
                            <svg class="h-auto absolute z-0 top-0 right-0 w-12 opacity-30 mt-6 mr-7 text-purple-300" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true"><path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path></svg>
                        </div>
                        <div class="h-auto bg-white rounded-xl shadow-sm p-6 relative bg-editable">
                            <div class="flex items-center border-b relative border-gray-100 border-solid pb-5 mb-5">
                                <img src="https://cdn.copymatic.ai/editor/img/05-ron-garrison.jpeg" class="object-cover is_avatar rounded-full mr-3 w-12 h-12 img-editable" />
                                <div class="relative">
                                    <p class="font-semibold text-gray-600 leading-none my-1">Ron Garrison</p>
                                    <p class="font-medium text-sm text-gray-400">Freelancer</p>
                                </div>
                            </div>
                            <p class="text-gray-500 z-10 leading-6 text-base relative bg-transparent shadow-none p-0" data-content="testimonials">"I have been using Hylo for the past 2 months and it has been a great tool for my business. I write blog posts, emails and social media captions with it."</p>
                            <svg class="h-auto absolute z-0 top-0 right-0 w-12 opacity-30 mt-6 mr-7 text-purple-300" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true"><path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path></svg>
                        </div>
                        <div class="h-auto bg-white rounded-xl shadow-sm p-6 relative bg-editable">
                            <div class="flex items-center border-b relative border-gray-100 border-solid pb-5 mb-5">
                                <img src="https://cdn.copymatic.ai/editor/img/06-charlie-madocks.jpeg" class="object-cover is_avatar rounded-full mr-3 w-12 h-12 img-editable" />
                                <div class="relative">
                                    <p class="font-semibold text-gray-600 leading-none my-1">Charlie Madocks</p>
                                    <p class="font-medium text-sm text-gray-400">Social Media Manager at 3D Vaze</p>
                                </div>
                            </div>
                            <p class="text-gray-500 z-10 leading-6 text-base relative bg-transparent shadow-none p-0" data-content="testimonials">"I was sceptical about the idea of AI at first however, when I saw my articles come out on time with a good quality and consistency I couldn't believe it."</p>
                            <svg class="h-auto absolute z-0 top-0 right-0 w-12 opacity-30 mt-6 mr-7 text-purple-300" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true"><path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path></svg>
                        </div>
                    </div>

                    <div class="col-span-4 lg:block hidden space-y-5">
                    <div class="h-auto bg-white rounded-xl shadow-sm p-6 relative bg-editable">
                            <div class="flex items-center border-b relative border-gray-100 border-solid pb-5 mb-5">
                                <img src="https://cdn.copymatic.ai/editor/img/03-jack-bennington.jpeg" class="object-cover is_avatar rounded-full mr-3 w-12 h-12 img-editable" />
                                <div class="relative">
                                    <p class="font-semibold text-gray-600 leading-none my-1">Jack Bennington</p>
                                    <p class="font-medium text-sm text-gray-400">CTO at TNT Solutions</p>
                                </div>
                            </div>
                            <p class="text-gray-500 z-10 leading-6 text-base relative bg-transparent shadow-none p-0" data-content="testimonials">"I'm a successful entrepreneur, and I think that Scribe is the best AI product on the market. It has helped me so much with my writing and productivity. I love how easy it is to use and that it saves me so much time."</p>
                            <svg class="h-auto absolute z-0 top-0 right-0 w-12 opacity-30 mt-6 mr-7 text-purple-300" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true"><path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path></svg>
                        </div>
                        <div class="h-auto bg-white rounded-xl shadow-sm p-6 relative bg-editable">
                            <div class="flex items-center border-b relative border-gray-100 border-solid pb-5 mb-5">
                                <img src="https://cdn.copymatic.ai/editor/img/08-jake-walters.jpeg" class="object-cover is_avatar rounded-full mr-3 w-12 h-12 img-editable" />
                                <div class="relative">
                                    <p class="font-semibold text-gray-600 leading-none my-1">Jake Walters</p>
                                    <p class="font-medium text-sm text-gray-400">Lead Developer at Edgeworks</p>
                                </div>
                            </div>
                            <p class="text-gray-500 z-10 leading-6 text-base relative bg-transparent shadow-none p-0" data-content="testimonials">"I am a person who needs to manage my time really well and Hylo AI has been the most helpful tool for me so far. They always deliver quality content and I love how the team is always available to help."</p>
                            <svg class="h-auto absolute z-0 top-0 right-0 w-12 opacity-30 mt-6 mr-7 text-purple-300" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true"><path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path></svg>
                        </div>
                        <div class="h-auto bg-white rounded-xl shadow-sm p-6 relative bg-editable">
                            <div class="flex items-center border-b relative border-gray-100 border-solid pb-5 mb-5">
                                <img src="https://cdn.copymatic.ai/editor/img/09-sam-robinson.jpeg" class="object-cover is_avatar rounded-full mr-3 w-12 h-12 img-editable" />
                                <div class="relative">
                                    <p class="font-semibold text-gray-600 leading-none my-1">Sam Robinson</p>
                                    <p class="font-medium text-sm text-gray-400">Lead Developer at Socnet</p>
                                </div>
                            </div>
                            <p class="text-gray-500 z-10 leading-6 text-base relative bg-transparent shadow-none p-0" data-content="testimonials"><span class="placeholder medium"></span> "Before using AI Business Solutions, I had trouble determining whether my business idea was feasible. But with their help, I was able to quickly analyze market trends and competition, and confidently launch my business."<span class="placeholder medium"></span></p>
                            <svg class="h-auto absolute z-0 top-0 right-0 w-12 opacity-30 mt-6 mr-7 text-purple-300" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true"><path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path></svg>
                        </div>
                    </div>

                </div>
            </div>
            <div class="bg-gradient-to-t from-purple-50 to-transparent h-96 w-full bottom-0 left-0 absolute z-20 flex items-end justify-center"></div>

        </div> */}

        <div class="bg-white py-10">
            <div class="max-w-7xl mx-auto py-4 px-4 bg-white sm:px-6 lg:px-8">
                <div class="text-center">
                    <h2 class="text-3xl font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-5xl primary_text editable">Pricing</h2>
                    <p class="mt-6 max-w-4xl mx-auto text-xl text-gray-500 secondary_text editable" data-content="website-subheadlines">Boost your productivity and grow your business.</p>
                </div>
                <div class="mt-16 grid gap-8 sm:grid-cols-2 lg:grid-cols-3" data-content="pricing-features">
                    <div class="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col space-y-6">
                        <div class="flex-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24">
  <path fill="#4C1D95" fill-rule="evenodd" d="M10 9a3 3 0 1 0 0-6a3 3 0 0 0 0 6Zm-7 9a7 7 0 1 1 14 0H3Z" clip-rule="evenodd"/>
</svg>


{/*  Free Subscription code*/}
                            <div class="mt-4 flex items-baseline">
                                <p class="text-5xl font-extrabold tracking-tight text-gray-900 primary_text editable">$0</p>
                                <p class="ml-1 text-xl font-semibold text-gray-900 primary_text editable">/free</p>
                            </div>
                            {/* <p class="mt-6 text-gray-500 text-base secondary_text editable" data-content="website-subheadlines">Hylo AI is a free AI sass company that help entrepreneurs and influencers get their writing and productivity game on point.</p> */}

                            <hr class="my-6" />

                            <ul role="list" class="space-y-4">
                                <li class="flex">
                                    <svg class="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    <p class="ml-3 text-gray-500 text-base secondary_text w-full editable" data-subcontent="feature">100 Credits per month</p>
                                </li>

                                <li class="flex">
                                    <svg class="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    <p class="ml-3 text-gray-500 text-base secondary_text w-full editable" data-subcontent="feature">Access to features and tools</p>
                                </li>

                                <li class="flex">
                                    <svg class="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    <p class="ml-3 text-gray-500 text-base secondary_text w-full editable" data-subcontent="feature">One user seat</p>
                                </li>

                                
                            </ul>
                        </div>
                        <Link to="/signup" className="bg-purple-100 text-purple-900 hover:bg-purple-200 mt-8 block w-full py-3 px-6 border-0 rounded-md text-center font-medium transition-all btn-editable">
                        <b>Get Started</b>
                        </Link>
                    </div>

                    <div class="
              relative
              p-8
              bg-white
              border border-gray-200
              rounded-2xl
              shadow-sm
              flex flex-col
              space-y-6
            ">

                {/*  Starter Subscription code*/}
                        <div class="flex-1">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-14 w-14 text-purple-900" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                            </svg>

                            <p class="absolute top-0 py-1.5 px-4 bg-purple-900 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2 secondary_text bg-editable editable">
                                Most popular
                            </p>
                            <div class="mt-4 flex items-baseline text-gray-900">
                                <p class="text-5xl font-extrabold tracking-tight text-gray-900 primary_text editable">$14</p>
                                <p class="ml-1 text-xl font-semibold text-gray-900 primary_text editable">/month</p>
                            </div>
                            {/* <p class="mt-6 text-gray-500 text-base secondary_text editable" data-content="website-subheadlines">Hylo AI helps entrepreneurs, influencers and start ups create high quality content and reach their marketing goals.</p> */}

                            <hr class="my-6" />

                            {/* <!-- Feature list --> */}
                            <ul role="list" class="space-y-4">
                            <li class="flex">
                                    <svg class="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    <p class="ml-3 text-gray-500 text-base secondary_text w-full editable" data-subcontent="feature">2,000 Credits per month</p>
                                </li>
                                <li class="flex">
                                    <svg class="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    <p class="ml-3 text-gray-500 text-base secondary_text w-full editable" data-subcontent="feature">Access to all features and tools</p>
                                </li>

                                {/* <!--<li class="flex">
                                    <svg class="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                    </svg> 
                                    <p class="ml-3 text-gray-500 text-base secondary_text w-full editable" data-subcontent="feature">Unlimited user seats</p>
                                </li>-->*/}

                                {/* <!--<li class="flex">
                                    <svg class="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    <p class="ml-3 text-gray-500 text-base secondary_text w-full editable" data-subcontent="feature">Unlimited projects</p>
                                </li>-->*/}

                                <li class="flex">
                                    <svg class="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    <p class="ml-3 text-gray-500 text-base secondary_text w-full editable" data-subcontent="feature">Email support</p>
                                </li>

                                <li class="flex">
                                    <svg class="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    <p class="ml-3 text-gray-500 text-base secondary_text w-full editable" data-subcontent="feature">Access to new beta tools</p>
                                </li>

                                
                            </ul>
                        </div>
                        <Link to="/signup" className="btn bg-gradient-to-r from-purple-900 to-purple-700 text-center text-white hover:bg-purple-700 hover:text-white py-3 px-8 rounded-lg mr-5">
                        <b>Get Started</b>
                        </Link>

                        {/*  Premium Subscription code*/}
                    </div>
                    <div class="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col space-y-6">
                        <div class="flex-1">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-14 w-14 text-purple-900" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                            </svg>
                            <div class="mt-4 flex items-baseline text-gray-900">
                                <p class="text-5xl font-extrabold tracking-tight text-gray-900 primary_text editable">$29</p>
                                <p class="ml-1 text-xl font-semibold text-gray-900 primary_text editable">/month</p>
                            </div>
                            {/* <p class="mt-6 text-gray-500 text-base secondary_text editable" data-content="website-subheadlines">Write and run your business with productivity tools powered by AI.</p> */}

                            <hr class="my-6" />

                            {/* <!-- Feature list --> */}
                            <ul role="list" class="space-y-4">
                            <li class="flex">
                                    <svg class="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    <p class="ml-3 text-gray-500 text-base secondary_text w-full editable" data-subcontent="feature">10,000 Credits per month</p>
                                </li>
                                <li class="flex">
                                    <svg class="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    <p class="ml-3 text-gray-500 text-base secondary_text w-full editable" data-subcontent="feature">Access to all features and tools</p>
                                </li>

                                <li class="flex">
                                    <svg class="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    <p class="ml-3 text-gray-500 text-base secondary_text w-full editable" data-subcontent="feature">Email Support</p>
                                </li>

                                <li class="flex">
                                    <svg class="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    <p class="ml-3 text-gray-500 text-base secondary_text w-full editable" data-subcontent="feature">Access to new beta tools</p>
                                </li>

                                {/* <!--<li class="flex">
                                    <svg class="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    <p class="ml-3 text-gray-500 text-base secondary_text w-full editable" data-subcontent="feature">Access beta tools</p>
                                </li>

                                <li class="flex">
                                    <svg class="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    <p class="ml-3 text-gray-500 text-base secondary_text w-full editable" data-subcontent="feature">Free updates and customer support</p>
                                </li>-->*/}

                                
                            </ul>
                        </div>

                        <Link to="/signup" className="bg-purple-100 text-purple-900 hover:bg-purple-200 mt-8 block w-full py-3 px-6 border-0 rounded-md text-center font-medium transition-all btn-editable">
                        <b>Get Started</b>
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-white">
            <div class="pt-16 py-16 mx-8 max-w-7xl">
                <div class="flex flex-wrap items-start justify-between pb-6 py-10 border-t border-solid lg:flex-row border-gray">
                    <a href="#_" class="flex order-first font-medium text-gray-900 lg:order-none lg:w-auto title-font">
                    <img className="w-40" src={logo} alt="Think" />
                    </a>
                    <div class="grid w-full grid-cols-2 mt-20 sm:grid-cols-4 gap-y-16 lg:gap-x-8 md:w-5/6 md:mt-0 md:pr-6">
                        <div class="md:justify-self-end">
                            <h3 class="font-semibold text-black primary_text">Product</h3>
                            <ul class="mt-6 space-y-4 text-sm">
                                <li>
                                    <a href="#_" class="relative inline-block text-gray-600 group link-editable hover:text-purple-600">
                                        <span class="absolute bottom-0 w-full transition duration-150 ease-out transform -translate-y-1 border-b border-black opacity-0 group-hover:opacity-100 group-hover:translate-y-1"></span>
                                        <span>Features</span>
                                    </a>
                                </li>

                              
                                <li>
                                    <a href="#_" class="relative inline-block text-gray-600 group link-editable hover:text-purple-600">
                                        <span class="absolute bottom-0 w-full transition duration-150 ease-out transform -translate-y-1 border-b border-black opacity-0 group-hover:opacity-100 group-hover:translate-y-1"></span>
                                        <span>Pricing</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="md:justify-self-end">
                            {/* <h3 class="font-semibold text-black primary_text">About</h3>
                            <ul class="mt-6 space-y-4 text-sm">
                                <li>
                                    <a href="#_" class="relative inline-block text-gray-600 group link-editable hover:text-purple-600">
                                        <span class="absolute bottom-0 w-full transition duration-150 ease-out transform -translate-y-1 border-b border-black opacity-0 group-hover:opacity-100 group-hover:translate-y-1"></span>
                                        <span>Our Story</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#_" class="relative inline-block text-gray-600 group link-editable hover:text-purple-600">
                                        <span class="absolute bottom-0 w-full transition duration-150 ease-out transform -translate-y-1 border-b border-black opacity-0 group-hover:opacity-100 group-hover:translate-y-1"></span>
                                        <span>Company</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#_" class="relative inline-block text-gray-600 group link-editable hover:text-purple-600">
                                        <span class="absolute bottom-0 w-full transition duration-150 ease-out transform -translate-y-1 border-b border-black opacity-0 group-hover:opacity-100 group-hover:translate-y-1"></span>
                                        <span>Our Team</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#_" class="relative inline-block text-gray-600 group link-editable hover:text-purple-600">
                                        <span class="absolute bottom-0 w-full transition duration-150 ease-out transform -translate-y-1 border-b border-black opacity-0 group-hover:opacity-100 group-hover:translate-y-1"></span>
                                        <span>Work With Us</span>
                                    </a>
                                </li>
                            </ul> */}
                        </div>
                        {/* <div class="md:justify-self-end">
                            <h3 class="font-semibold text-black primary_text">Resources</h3>
                            <ul class="mt-6 space-y-4 text-sm">
                                <li>
                                    <a href="#_" class="relative inline-block text-gray-600 group link-editable hover:text-purple-600">
                                        <span class="absolute bottom-0 w-full transition duration-150 ease-out transform -translate-y-1 border-b border-black opacity-0 group-hover:opacity-100 group-hover:translate-y-1"></span>
                                        <span>Help Center</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#_" class="relative inline-block text-gray-600 group link-editable hover:text-purple-600">
                                        <span class="absolute bottom-0 w-full transition duration-150 ease-out transform -translate-y-1 border-b border-black opacity-0 group-hover:opacity-100 group-hover:translate-y-1"></span>
                                        <span>Developer API</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#_" class="relative inline-block text-gray-600 group link-editable hover:text-purple-600">
                                        <span class="absolute bottom-0 w-full transition duration-150 ease-out transform -translate-y-1 border-b border-black opacity-0 group-hover:opacity-100 group-hover:translate-y-1"></span>
                                        <span>Our Blog</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#_" class="relative inline-block text-gray-600 group link-editable hover:text-purple-600">
                                        <span class="absolute bottom-0 w-full transition duration-150 ease-out transform -translate-y-1 border-b border-black opacity-0 group-hover:opacity-100 group-hover:translate-y-1"></span>
                                        <span>Status</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#_" class="relative inline-block text-gray-600 group link-editable hover:text-purple-600">
                                        <span class="absolute bottom-0 w-full transition duration-150 ease-out transform -translate-y-1 border-b border-black opacity-0 group-hover:opacity-100 group-hover:translate-y-1"></span>
                                        <span>Sitemap</span>
                                    </a>
                                </li>
                            </ul>
                        </div> */}
                        {/* <div class="md:justify-self-end">
                            <h3 class="font-semibold text-black primary_text">Contact</h3>
                            <ul class="mt-6 space-y-4 text-sm">
                                <li>
                                    <a href="#_" class="relative inline-block text-gray-600 group link-editable hover:text-purple-600">
                                        <span class="absolute bottom-0 w-full transition duration-150 ease-out transform -translate-y-1 border-b border-black opacity-0 group-hover:opacity-100 group-hover:translate-y-1"></span>
                                        <span>Advertising</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#_" class="relative inline-block text-gray-600 group link-editable hover:text-purple-600">
                                        <span class="absolute bottom-0 w-full transition duration-150 ease-out transform -translate-y-1 border-b border-black opacity-0 group-hover:opacity-100 group-hover:translate-y-1"></span>
                                        <span>Press</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#_" class="relative inline-block text-gray-600 group link-editable hover:text-purple-600">
                                        <span class="absolute bottom-0 w-full transition duration-150 ease-out transform -translate-y-1 border-b border-black opacity-0 group-hover:opacity-100 group-hover:translate-y-1"></span>
                                        <span>Partners</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#_" class="relative inline-block text-gray-600 group link-editable hover:text-purple-600">
                                        <span class="absolute bottom-0 w-full transition duration-150 ease-out transform -translate-y-1 border-b border-black opacity-0 group-hover:opacity-100 group-hover:translate-y-1"></span>
                                        <span>Email</span>
                                    </a>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>

                <div class="flex flex-col items-center justify-between py-10 border-t border-solid lg:flex-row border-gray">
                    <ul class="flex flex-wrap space-x-5 text-xs">
                        <li class="mb-6 text-center flex-full text-gray-600 lg:flex-none lg:mb-0">© 2022 Hylo AI.</li>
                        <li class="ml-6">
                            <a href="#_" class="relative inline-block text-black group hover:text-purple-600 link-editable">
                                <span class="absolute bottom-0 w-full transition duration-150 ease-out transform -translate-y-1 border-b border-black opacity-0 group-hover:opacity-100 group-hover:translate-y-0"></span>
                                <span>Privacy Policy</span>
                            </a>
                        </li>
                        <li class="ml-6">
                            <a href="#_" class="relative inline-block text-black group hover:text-purple-600 link-editable">
                                <span class="absolute bottom-0 w-full transition duration-150 ease-out transform -translate-y-1 border-b border-black opacity-0 group-hover:opacity-100 group-hover:translate-y-0"></span>
                                <span>Disclaimers</span>
                            </a>
                        </li>
                        <li class="ml-6">
                            <a href="#_" class="relative inline-block text-black group hover:text-purple-600 link-editable">
                                <span class="absolute bottom-0 w-full transition duration-150 ease-out transform -translate-y-1 border-b border-black opacity-0 group-hover:opacity-100 group-hover:translate-y-0"></span>
                                <span>Terms and Conditions</span>
                            </a>
                        </li>
                    </ul>

                    <ul class="flex items-center mt-10 space-x-5 lg:mt-0">
                        <li>
                            <a href="#" class="text-gray-600 hover:text-gray-900 link-editable">
                                <span class="sr-only">Facebook</span>
                                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd"></path>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="text-gray-600 hover:text-gray-900 link-editable">
                                <span class="sr-only">Instagram</span>
                                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd"></path>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="text-gray-600 hover:text-gray-900 link-editable">
                                <span class="sr-only">Twitter</span>
                                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>






        </div>
    </div>
</body>
</html>

  {/* <div className="border-b border-gray-300 bg-white shadow-sm ">
    */}
    {/* <div className="container flex mx-auto px-4 md:px-28 flex select-none">
        <NavLink to="/"
                 exact
                 tabIndex={-1}
                 onClick={() =>
            this.props.store.toolsKeyword = ""}
            activeClassName="bg-white-100 hover: text-gray-800 transition"
            className="text-lg flex py-5 px-6 lg:py-4 lg:px-0 cursor-pointer  pl-0 rounded-t-md font-semibold transition items-center pl-0 ">

            <div className="">Think Space AI</div>

        </NavLink>

        <div className="relative text-gray-400 focus-within:text-green-500 flex flex-1 ">
            <label htmlFor="q" className="absolute inset-y-0 left-0 top-0 bottom-0 hidden md:flex items-center lg:pl-2 ">
                <div type="submit" className="p-2 focus:outline-none focus:shadow-outline ">
                    {/* <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-6 h-6 transition"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg> */}
                    {/*
                </div>
            </label>

        </div>

        <NavLink to="/login"
                 exact
                 tabIndex="-1"
                 activeClassName="bg-green-100 hover:bg-green-200 text-green-800 transition"
                 className={`text-lg flex py-3 px-6 xl:py-4 xl:px-8 cursor-pointer ${this.fromColor} hover:bg-gray-100 rounded-t-md font-medium transition items-center`}>
            <UserCircleIcon className="w-7 h-7 lg:mr-4 transition" />
            <div className="hidden lg:block"> Log In</div>
        </NavLink>  */}
        {/* <NavLink to="/"
                     exact
                     tabIndex={-1}
                     onClick={() =>
            this.props.store.toolsKeyword = ""}
            activeClassName="bg-white-100 hover:bg-gray-200 text-gray-800 transition"
            className="text-lg flex py-3 px-6 lg:py-4 lg:px-8 cursor-pointer hover:bg-gray-100 rounded-t-md font-medium transition items-center">
            <div className="hidden lg:block">Sign Up</div>
        </NavLink> */}

        {/* <div className="hidden lg:block" style={{ display: 'flex' , justifyContent: 'center' , paddingTop: '10px' , paddingBottom: '10px' }}>
            <Link to="/signup" className="hidden lg:block btn bg-gradient-to-r from-purple-900 to-purple-700 text-white hover:bg-purple-700 hover:text-white py-3 px-10 rounded-lg mr-5">
            <b>Sign Up</b>
            </Link>
        </div>





    </div>

</div> */}
                                {/* <div style={{ backgroundColor: "white" , color: "black" , display: "flex" , alignItems: "center" , height: "100vh" , flexDirection: "column" , paddingTop: "30px" , paddingLeft: "20px" , paddingRight: "20px" }}>
    <div style={{ width: "100%" }}>
        <h1 className="text-black text-center font-bold text-4xl md:text-5xl mb-4">Streamline Your Work</h1>
        <h3 className="text-darkgrey text-center font-light text-2xl md:text-3xl mb-4">
            Unlock the full potential of your workflow with our cutting-edge AI powered tools.
        </h3>
        <div className="flex justify-center mt-10">
            <Link to="/signup" className="btn bg-gradient-to-r from-purple-900 to-purple-700 text-white hover:bg-purple-700 hover:text-white py-3 px-10 rounded-lg mr-5">
            <b>Sign Up Now</b>
            </Link>
        </div>

    </div>
    <div style={{alignItems: "center" , marginTop: "50px" }}>
        <video style={{
               position: 'absolute' ,
               top: '70%' ,
               left: '50%' ,
               transform: 'translate(-50%, -50%)' ,
               width: '80%' ,
               height: '60%' ,
               border: `15px solid #7b1fa2`,
               borderRadius: '20px' ,
               boxShadow: '0px 0px 25px #9478FF' ,
               }}
               controls autoplay loop>
            <source src="./video/sizzle.webm" type="video/webm"></source>
        </video>

    </div> */}

    {/*
</div> */}




      </>
    )
  }
}

export default withRouter(Landing);