import jobad from './jobad';
import summarize from './summarize';
import productdesc from './productdesc';
import codeInterpret from './interpret';
import intro from './intro';
import rephrase from './rephrase';
import emailgen from './emailgen';
import expand from './expand';
import helloworld from './helloworld';
import example from './example';
import leancanvas from './leancanvas';
import businessidea from './businessidea';
import businessname from './businessname';
import businesscoach from './businesscoach';
import freestyle from './freestyle';
import explain from './explain';
import socialcalendar from './socialcalendar';
import actionplan from './actionplan';
import businessplan from './businessplan';
import gtm from './gtm';
import sms from './sms';
import marketingplan from './marketingplan';


const TOOLS = [

	//Business
	businessidea,
	businessname,
	leancanvas,
	businessplan,
	gtm,
	actionplan,
	businesscoach,
	

	//Social Media
	marketingplan,
	sms,
	socialcalendar,
	

	//Productivity
	codeInterpret,
	emailgen,
	expand,
	
	rephrase,
	intro,
	jobad,
	summarize,
	helloworld,
	example,
	freestyle,
	explain,
	

	productdesc,

]

export default TOOLS
