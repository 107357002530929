import {
	TemplateIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "Lean Canvas (Business Model)",
	desc: "Quickly breakdown and analyze business idea's and ventures.",
	category: "Business",
	Icon: TemplateIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "indigo-500",
	toColor: "purple-500",

	to: "/ai/business/leancanvas",
	api: "/ai/business/leancanvas",

	output: {
		title: "Lean Canvas",
		desc: "The following lean canvas has been generated",
		// Icon: RefreshIcon,
		// color: "",
	},

	prompts: [{
		title:"Enter the business name and detailed description of the business idea you want to evaluate.",
		desc: " ",
		// n: 1,
		prompts: [
			{ 
				title: "Business Name (optional)", 
				attr: "b_name",  
				value: "", 
				placeholder: "Gin and Juicery", 
				label: " ",
				type: "text",
				maxLength: 150,
				// max: 100,
				min: 0,
				required: false,
				error: "",
				example: "Yenisi Beauty",
			},
			{ 
				title: "Business Description", 
				attr: "b_desc",  
				value: "", 
				placeholder: "100% organic fresh pressed juicery made to order. Offers juice flavors that mock cocktails", 
				label: " ",
				type: "textarea",
				maxLength: 1000,
				// max: 100,
				min: 50,
				required: true,
				error: "",
				example: "A 100% certified organic skin brand using real ingredients. to moisturize exfoliate and grow either your skin or hair.",
			},
			
		],
		example: {
			output: `
Lean Canvas for Yenisi Beauty:

Problem
- Many skincare products on the market contain harmful chemicals that can irritate and damage the skin.
- Customers want natural and organic skincare products, but there is a lack of transparency and trust in the industry.
- Customers often have trouble finding products that meet their specific skincare needs, such as moisturizing, exfoliating, and promoting hair growth.
			
Customer Segments
- Health-conscious individuals who prioritize natural and organic products.
- Customers with sensitive skin or specific skincare concerns.
- Those who are interested in hair growth and maintenance.
			
Unique Value Proposition (UVP)
- Yenisi Beauty offers 100% certified organic products made with real ingredients that are safe and effective for all skin types.
- Yenisi Beauty's products are formulated to address specific skincare needs, such as moisturizing, exfoliating, and promoting hair growth, using natural ingredients.
- The company is transparent about its ingredients and processes, allowing customers to trust the products they're using.
			
Solution
- Yenisi Beauty offers a range of skincare products, including moisturizers, exfoliators, and hair growth oils.
- The products are made with real, organic ingredients such as shea butter, coconut oil, and essential oils.
- Yenisi Beauty products are free from harmful chemicals such as parabens, sulfates, and artificial fragrances.
			
Channels
- Online website and store
- Social media platforms, such as Instagram and Facebook
- Partnering with natural beauty stores and salons
- Direct marketing to health-conscious individuals
			
Revenue Streams
- Product sales through the website, social media, and partnering stores and salons
- Subscription services for loyal customers
- Bundled product packages
			
Cost Structure
- Ingredient and packaging costs
- Labor costs for production and distribution
- Marketing and advertising expenses
- Overhead expenses, such as office space and equipment
			
Key Metrics
- Customer acquisition costs
- Conversion rates
- Customer retention and lifetime value
- Social media engagement and growth
- Sales revenue
			
Unfair Advantage
- Yenisi Beauty's 100% certified organic products are unique and fulfill a growing need in the skincare industry.
- The company's transparency about its ingredients and processes sets it apart from competitors.
- Yenisi Beauty's focus on natural, organic ingredients and specific skincare needs gives it a competitive edge in the market.
			
Now that you have your lean canvas for Yenisi Beauty, head to the Go-To-Market Strategy tool to put this business idea in action!`,
			// outputs: [],
			// Icon: RefreshIcon,
			color: "blue",
		}	
	}]
		
}

export default obj

