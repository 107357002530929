import {
	MapIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "Go-to-Market Strategy",
	desc: "Put your business idea in motion with a Go-To-Market Strategy.",
	category: "Business",
	Icon: MapIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "indigo-500",
	toColor: "purple-500",

	to: "/ai/business/gtm",
	api: "/ai/business/gtm",

	output: {
		title: "Go-to-Market Strategy",
		desc: "The following Go-to-Market Strategy was generated",
		Icon: false,
		color: "blue",
	},

	prompts: [{
		title:"Enter infromation for the idea you want a Go-To-Market Strategy for.",
		desc: " ",
		// n: 1,
		prompts: [
            { 
				title: "Business Goals or Objectives", 
				attr: "bus_goal",  
				value: "", 
				placeholder: "", 
				label: "",
				type: "textarea",
				maxLength: 1000,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: `-Launch bet product in 1 month and launch production in 3 months. 
-Raise 500,000 to be spent mainly  on marketing and product engineering and design.
-Achieve 20k MRR within 6 months `,
			},
            { 
				title: "Business Description", 
				attr: "bus_desc",  
				value: "", 
				placeholder: "", 
				label: "",
				type: "textarea",
				maxLength: 1000,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: `Nexa AI is an AI sass company.  Nexa has tools for... `,
			},

           
            { 
				title: "Resources available (such as budget, personnel, and equipment etc.)", 
				attr: "avail_res",  
				value: "", 
				placeholder: "", 
				label: "",
				type: "textarea",
				maxLength: 1000,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: `-We have a beta site hosted ready to start testing 
-1 technical product manager 
-1 product manager`
			},
            { 
				title: "Timeline/Deadline for completion", 
				attr: "timeline",  
				value: "", 
				placeholder: "", 
				label: "",
				type: "text",
				maxLength: 1000,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: `-Launch bet product in 1 month and launch production in 3 months.`,
			},

		],
		example: {
			output: `Go-to-Market Strategy:

Phase 1 (Month 1):

a. Finalize beta product: The priority for the first week is to finalize the beta product. Collaborate with the technical product manager to ensure that the product features and specifications meet the needs of potential customers. Define clear user personas and use cases that will resonate with the target audience.

b. Conduct beta testing: The second and third weeks will be dedicated to beta testing the product. The technical product manager should work with potential users to gather feedback and ensure that the product meets their needs. The product manager should also collaborate with marketing to develop messaging for the beta launch.

c. Beta Launch: In week four, launch the beta product to a select group of users. Leverage social media and your network for distribution. Encourage users to provide feedback and identify any bugs or features that could be improved before the full product launch.

Phase 2 (Months 2-3):

a. Product enhancements: Based on feedback from beta users, the technical product manager should make any necessary enhancements to the product. Work with the product manager to prioritize the features that need to be improved before the full launch.

b. Develop marketing strategy: In week 6, work with the product manager to develop a comprehensive marketing strategy that will be used for the full product launch. The main goal is to generate awareness and interest in the product through targeted campaigns.

c. Begin building sales pipeline: Establish a clear process for onboarding new users, and begin building a sales pipeline. This will be critical for achieving the 20k MRR goal within six months.

d. Full Product Launch: In the final week of month three, release the full product to the market. Leverage the marketing strategy developed in week 6 to generate awareness and encourage users to try the product.

Potential Pitfalls or Challenges:

One of the biggest challenges is generating awareness and interest for the product in a crowded market. To overcome this, focus on building a strong sales pipeline that will help to drive revenue quickly. Another potential challenge is getting customers to adopt the product. To address this, make sure that the product is user-friendly and offers clear value to users, and continue to gather feedback even after the full product launch. 

Now that you have your action plan, develop your marketing strategy with our Marketing Plan tool!s`,
			// outputs: [
			// 	"The sun is very old, over 4.5 billion years",
			// 	"At 10,000 degrees, sun is also very hot",
			// 	"Gasses called coronal mass ejections erupt from the sun",
			// ],
			// Icon: RefreshIcon,
			color: "blue",
		}
	}]
		
}

export default obj

