import {
	DocumentReportIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "Social Media Strategy",
	desc: "Generate an engaging social media strategy for your business. ",
	category: "Social",
	Icon: DocumentReportIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "purple-500",
	toColor: "indigo-500",

	to: "/ai/social/sms",
	api: "/ai/social/sms",

	output: {
		title: "Social Media Strategy",
		desc: "The following strategy was generated",
		Icon: false,
		color: "blue",
	},

	prompts: [{
		title:"Enter the following information to have your social media strategy generated.",
		desc: " ",
		// n: 1,
		prompts: [
            { 
				title: "Business/brand overview", 
				attr: "b_desc",  
				value: "", 
				placeholder: "", 
				label: "",
				type: "textarea",
				maxLength: 1000,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: "Hylo AI is an AI SASS company.  Hylo has tools for entrepreneurs that helps them during the ideation and planning phases of business building. ",
			},
            { 
				title: "Social Media Platforms", 
				attr: "b_plat",  
				value: "", 
				placeholder: "", 
				label: "",
				type: "textarea",
				maxLength: 500,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: "Twitter, Instagram, Tik Tok",
			},
            { 
				title: "Goals or Objectives", 
				attr: "b_goal",  
				value: "", 
				placeholder: "", 
				label: "",
				type: "textarea",
				maxLength: 500,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: "50k MRR in 12 months",
			},
			{ 
				title: "Target Audience (optional)", 
				attr: "target",  
				value: "", 
				placeholder: "", 
				label: "",
				type: "textarea",
				maxLength: 500,
				// max: 100,
				min: 0,
				required: false,
				error: "",
				example: "",
			},
			{ 
				title: "Unique Value Proposition (optional)", 
				attr: "uvp",  
				value: "", 
				placeholder: "", 
				label: "",
				type: "textarea",
				maxLength: 500,
				// max: 100,
				min: 0,
				required: false,
				error: "",
				example: "",
			},
            
            

		],
		example: {
			output: `Social Media Strategy:

A. Strategy Recommendations:
- Use a content marketing approach to create informative and educational content related to entrepreneurship, ideation, and business planning.
- Focus on using social media as a means to engage with the target audience by responding to comments, messages, and mentions, and by asking for feedback.
- Use social media channels as a tool to drive traffic to the Hylo AI website or landing page.
			
B. Content Strategy:
-  Create and distribute educational blog posts, infographics, and video content on Instagram, Tik Tok, and Twitter.
-  Share success stories and case studies of people who use their product on Instagram and Twitter.
-  Post motivational quotes, entrepreneurship tips, and industry news on Instagram and Twitter.
			   
C. Social Media Channels:
- Instagram
- Tik Tok
- Twitter
			
D. Engagement Tactics:
-  Respond to all comments on Instagram, Tik Tok, and Twitter
-  Create an online community through Instagram and Twitter hashtags
-  Host weekly Q&A sessions on Twitter and engage with users in real-time
-  Share user-generated content on Hylo's social media channels
			
E. KPI's:
-  Increase engagement rates on Instagram, Tik Tok, and Twitter by at least 10% each month
-  Increase website traffic to the Hylo AI website or landing page by 15% over the next six months
			
Target Audience:
			
A. Demographics:
- Age: 18-34
- Gender: Male and Female
- Location: United States, Canada, and the United Kingdom
- Education: College-educated, entrepreneurs or aspiring entrepreneurs
			
B. Psychographics:
- Interested in entrepreneurship, business ideas, and innovation
- Values education and self-improvement
- Active social media users and content consumers
			
C. Customer Persona:
- Name: Alex
- Age: 27
- Occupation: Aspiring entrepreneur 
- Income: $50,000
- Background: Graduated college with a business degree and has several business ideas but is not sure how to start
- Goals: To start a successful business and create a lifestyle where he can work for himself. 
- Pain Points: Lack of knowledge about business ideation and planning, unsure about steps to take to start a business, and difficulty finding a supportive community.
			
Potential Pitfalls and Challenges:
- Ensuring that the content is informative and educational while still being engaging and interesting for the target audience can be a balancing act.
- Consistency is key, so it's important to have a team dedicated to executing the social media plan to avoid lapses in content.
- The target audience may be desensitized to motivational posts and quotes, so it's important to mix up content with more informational posts.
			
Now that you have your social media strategy, develop your content calendar with our Content Calendar Tool!`,
			// outputs: [
			// 	"The sun is very old, over 4.5 billion years",
			// 	"At 10,000 degrees, sun is also very hot",
			// 	"Gasses called coronal mass ejections erupt from the sun",
			// ],
			// Icon: RefreshIcon,
			color: "blue",
		}
	}]
		
}

export default obj