import {
	IdentificationIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "Business Name",
	desc: "Find the perfect name for your next business venture.",
	category: "Business",
	Icon: IdentificationIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "indigo-500",
	toColor: "purple-500",
	to: "/ai/business/businessname",
	api: "/ai/business/businessname",

	output: {
		title: "Business Name",
		desc: "The following names have been generated.",
		// Icon: RefreshIcon,
		// color: "",
	},

	prompts: [{
		title:"",
		desc: " ",
		// n: 1,
		prompts: [
			{ 
				title: "Business Description", 
				attr: "bus_desc",  
				value: "", 
				placeholder: "A mobile-based platform for connecting patients with local laboratories for blood test and other diagnostic services", 
				label: " ",
				type: "textarea",
				maxLength: 1000,
				// max: 100,
				min: 5,
				required: true,
				error: "",
				example: "Virtual reality physical therapy platform for patients recovering from injuries or surgeries.",
			},
			
		],
		example: {
			output: `
1. VirtuTherapy
2. RevoPT
3. RecoVR 
4. HealVR
5. TherapEase
6. MotionVR
7. RecoupVR
8. Rehabit
9. BodyVision
10. RehabVR
`,
			// outputs: [],
			// Icon: RefreshIcon,
			// color: "",
		}
	}]
		
}

export default obj

